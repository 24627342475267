export default function debounce(fn, delay) {
  let timeout = null;
  return function () {
    clearTimeout(timeout);
    const args = arguments;
    const self = this;
    timeout = setTimeout(function () {
      fn.apply(self, args);
    }, delay);
  };
}
