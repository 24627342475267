<template>
  <div class="page">
    <RegisterOnboardForm :formData="formData" />
  </div>
</template>

<script>
import RegisterOnboardForm from "@/components/auth/register/RegisterOnboardForm.vue";

export default {
  metaInfo: {
    title: "Onboarding",
  },

  components: { RegisterOnboardForm },

  data: () => ({
    formData: {},
  }),
};
</script>
