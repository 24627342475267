<template>
  <div class="card">
    <div class="text-center">
      <h1>{{ $t("register.onboard.title") }}</h1>
      <div class="subtitle-1">{{ $t("register.onboard.subtitle") }}</div>
    </div>

    <!-- Form -->
    <v-form
      v-model="formValid"
      @submit.prevent="submit"
      class="mt-5"
      autocomplete="off"
    >
      <div>
        <!-- Username -->
        <v-text-field
          v-model="formData.username"
          @input="checkUsername"
          :label="$t('common.username')"
          :loading="$loading('auth/usernameValid')"
          :maxlength="$config.username.maxLength"
          :rules="[
            $rules.username,
            !$loading('auth/usernameValid') || $t('register.checkingUsername'),
            usernameValid || $t('register.usernameTaken'),
          ]"
          prepend-inner-icon="mdi-account"
          filled
        />

        <!-- Country -->
        <v-select
          filled
          :label="$t('register.onboard.country')"
          prepend-inner-icon="mdi-earth"
          v-model="formData.country"
          :items="country"
          item-text="name"
          item-value="name"
          menu-props="offsetY"
        />

        <!-- Favorite club -->
        <v-select
          filled
          :label="$t('register.onboard.favouriteFootballClub')"
          prepend-inner-icon="mdi-soccer"
          v-model="formData.favourite_football_club_id"
          :items="$store.getters['clubs/all']"
          :loading="$loading('clubs/fetchAll')"
          item-text="name"
          item-value="id"
          menu-props="offsetY"
        />
      </div>

      <!-- Hashtags -->
      <chip-select
        :label="$t('hashtags.hashtags')"
        v-model="formData.hashtags"
        :items="$store.getters['hashtags/default']"
        :loading="$loading('hashtags/fetchDefault')"
        multiple
        :rules="[$rules.required, $rules.selection.min(3)]"
        class="hashtag__select"
        color="secondary"
      />

      <v-spacer />
      <div>
        <!-- ApiResponse -->
        <api-response :response="formResponse" />

        <!-- Next button -->
        <v-btn
          color="primary"
          type="submit"
          :disabled="!formValid || $loading('auth/usernameValid')"
          :loading="$loading('auth/registerOnboard')"
          block
          class="mt-5"
        >
          {{ $t("register.completeRegistration") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import countries from "../../../config/countries.json";
import debounce from "@/utils/debounce";

export default {
  props: {
    formData: Object,
  },

  data: () => ({
    formValid: false,
    formResponse: null,
    usernameValid: false,
    country: countries,
  }),

  created() {
    this.$store.dispatch("clubs/fetchAll");
    this.$store.dispatch("hashtags/fetchDefault");
  },

  methods: {
    submit() {
      this.formResponse = null;
      this.$store
        .dispatch("auth/registerOnboard", {
          token: this.$route.params.token,
          data: this.formData,
        })
        .then(() => {
          this.$router.replace({
            name: "auth.login",
            query: {
              "registration-success": 1,
            },
          });
        })
        .catch((data) => {
          this.formResponse = data;
        });
    },

    checkUsername: debounce(function () {
      this.usernameValid = false;

      if (this.formData.username.length < 4) {
        return;
      }

      this.$store
        .dispatch("auth/usernameValid", this.formData.username)
        .then((res) => {
          this.usernameValid = res.username_valid;
        });
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
:deep .hashtag__select {
  text-align: center;
  .v-label {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .v-chip__content:before {
    content: "#";
  }
}
</style>
